import { useRouter } from 'next/router';
import { FormattedMessage as T } from 'react-intl';

import BannerContent from '@otovo/shared/components/NotificationBanner/BannerContent';
import BannerCTA from '@otovo/shared/components/NotificationBanner/BannerCTA';
import NotificationBanner from '@otovo/shared/components/NotificationBanner/NotificationBanner';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { logUIEvent } from '@otovo/shared/lib/analytics/analytics';
import { createRedirectHref } from '@otovo/shared/lib/handleInterestCreated/handleInterestCreated';
import { renderAddress } from '@otovo/shared/lib/renderAddress';
import { Otovo$Locale } from '@otovo/shared/types/otovoweb';

import {
  clearStoredInterest,
  getStoredInterest,
} from '@otovo/shared/components/InterestRegistration/storeInterestUtils';
import { useEffect, useState } from 'react';
import m from './messages';

const WelcomeBackComponent = () => {
  const { BU_CONFIG } = useGlobalConfig();
  const { locale } = useRouter() as { locale: Otovo$Locale };
  const [isDismissed, setIsDismissed] = useState(false);
  const [storedInterest, setStoredInterest] = useState(null);

  useEffect(() => {
    setStoredInterest(getStoredInterest());
  }, []);

  if (!storedInterest) {
    return null;
  }

  const renderAddressAndLink = () => {
    const renderedAddressLines = renderAddress(
      storedInterest.address.address_lines,
    );
    const { interestId } = storedInterest;
    const salesflowHref = createRedirectHref({
      locale,
      id: interestId,
      redirectBaseUrl: BU_CONFIG.BREEZE_REDIRECT_BASE_URL,
      queryParams: {
        utm_source: 'otovo',
        utm_medium: 'web',
        utm_campaign: 'welcome_back',
      },
    });

    const link = (
      <BannerCTA
        onClick={() => {
          setIsDismissed(true);
          logUIEvent({ action: 'User clicked the "welcome back" banner' });
        }}
        href={salesflowHref}
      >
        {renderedAddressLines ? (
          <T {...m.goToProductsPage} />
        ) : (
          <T {...m.goToProductsPageDetailed} />
        )}
      </BannerCTA>
    );

    if (renderedAddressLines) {
      return (
        <>
          <T
            {...m.description}
            values={{
              address: renderedAddressLines,
            }}
          />{' '}
          {link}
        </>
      );
    }

    return link;
  };

  logUIEvent(
    {
      action: 'Displayed welcome back banner to the user',
    },
    { once: true },
  );

  return (
    <NotificationBanner
      onDismiss={() => {
        clearStoredInterest();
        setIsDismissed(true);
        logUIEvent({ action: 'User dismissed the "welcome back" banner' });
      }}
      isDismissed={isDismissed}
      type="cta"
    >
      <BannerContent>
        <span className="font-medium">
          <T {...m.title} />
        </span>{' '}
        <span data-testid="welcome-back-banner">{renderAddressAndLink()}</span>
      </BannerContent>
    </NotificationBanner>
  );
};

export default WelcomeBackComponent;
