import useUTMData from '@otovo/shared/hooks/useUTMData';
import { useEffect, useState } from 'react';
import useCampaignValidator from '../../hooks/useCampaignValidator';
import DiscountBanner from './DiscountBanner';
import WelcomeBack from './WelcomeBack/WelcomeBack';

type Props = {
  children?: JSX.Element;
};

const LandingPageNotification = ({ children }: Props) => {
  const [mounted, setMounted] = useState(false);

  const utmData = useUTMData();
  const { validatedCampaign } = useCampaignValidator({ utm_data: utmData });

  useEffect(() => {
    // Need to guard as Emotion doesn't work with SSR.
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  if (children) {
    return children;
  }

  if (validatedCampaign?.discounts) {
    return <DiscountBanner discounts={validatedCampaign.discounts} />;
  }

  return <WelcomeBack />;
};

export default LandingPageNotification;
