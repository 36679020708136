import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'Welcome';

export default defineMessages({
  referreeFound: {
    id: `${I18N_NAMESPACE}.referreeFound`,
    defaultMessage: `Referral registered!`,
  },
  referreeWelcome: {
    id: `${I18N_NAMESPACE}.referreeWelcome`,
    defaultMessage: `Enter your address below to claim your discount.`,
  },
  referralNotFound: {
    id: `${I18N_NAMESPACE}.referralNotFound`,
    defaultMessage: `Sorry, we couldn't find your referral.`,
  },
  discountHeading: {
    id: `${I18N_NAMESPACE}.discountHeading`,
    defaultMessage: 'Claim your solar discount!',
  },
  discountFixedPercent: {
    id: `${I18N_NAMESPACE}.discountFixedPercent`,
    defaultMessage: `Search your address and get {discount} off!`,
  },
  discountMonths: {
    id: `${I18N_NAMESPACE}.discountMonths`,
    defaultMessage: `Search your address and receive {month} month discount on leasing!`,
  },
});
