import log from 'loglevel';

export function renderAddress(address?: Array<string> | null): string {
  if (Array.isArray(address) && address.length) {
    return address.reduce((current, next) => `${current}, ${next}`);
  }

  log.debug('Address lines are not valid:', address);
  return '';
}
