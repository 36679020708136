import { useState } from 'react';
import { IntlShape, FormattedMessage as T, useIntl } from 'react-intl';

import FormattedCurrency from '@otovo/shared/components/Currency/FormattedCurrency';
import Checkmark from '@otovo/shared/components/Icons/Checkmark/Checkmark';
import BannerContent from '@otovo/shared/components/NotificationBanner/BannerContent';
import NotificationBanner from '@otovo/shared/components/NotificationBanner/NotificationBanner';
import { logToSentry } from '@otovo/shared/lib/sentry';
import { Cloud$ValidatedCampaign } from '@otovo/shared/types/cloudApi';

import m from './messages';

type Discount = Cloud$ValidatedCampaign['discounts'][0];

const formatMessage = (discount: Discount, intl: IntlShape) => {
  switch (discount.type) {
    case 'fixed':
      return intl.formatMessage(m.discountFixedPercent, {
        discount: (
          <span className="font-bold">
            <FormattedCurrency value={discount.value} />
          </span>
        ),
      });
    case 'free_months':
      return intl.formatMessage(m.discountMonths, {
        month: <span className="font-bold">{discount.value}</span>,
      });
    case 'percentage':
      return intl.formatMessage(m.discountFixedPercent, {
        discount: <span className="font-bold">{discount.value}%</span>,
      });

    default:
      logToSentry(`Unsupported discount type ${discount.type}`, discount);
      return null;
  }
};

function getDiscount(discounts: Cloud$ValidatedCampaign['discounts']) {
  const [first] = discounts;
  // Prioritize leasing
  return discounts.find((discount) => discount.type === 'free_months') || first;
}

type Props = {
  discounts: Cloud$ValidatedCampaign['discounts'];
};

const DiscountBanner = ({ discounts }: Props) => {
  const intl = useIntl();
  const [isDismissed, setIsDismissed] = useState(false);
  const handleDismiss = () => {
    setIsDismissed(true);
  };

  const firstDiscount = getDiscount(discounts);
  const message = formatMessage(firstDiscount, intl);

  return (
    <NotificationBanner
      type="success"
      isDismissed={isDismissed}
      onDismiss={handleDismiss}
    >
      <BannerContent>
        <Checkmark
          bg="green_50"
          size="4"
          mr="2"
          display="inline-block"
          lineHeight="0"
          verticalAlign="top"
        />
        <span className="font-medium">
          <T {...m.discountHeading} />
        </span>{' '}
        {message}
      </BannerContent>
    </NotificationBanner>
  );
};

export default DiscountBanner;
