import { useEffect, useState } from 'react';
import UtmLocalStorage, {
  UtmData,
} from '../lib/analytics/queryParams/UtmLocalStorage';

/**
 * Hook that let you "subscribe" to changes in the UTM localStorage
 *
 * Useful when you want to do something if UTM data changes
 */

function useUTMData() {
  const [utmData, setUtmData] = useState<UtmData | null>(null);

  useEffect(() => {
    setUtmData(UtmLocalStorage.retrieve());
  }, []);

  useEffect(() => {
    function onChange() {
      setUtmData(UtmLocalStorage.retrieve());
    }
    window.addEventListener('storage', onChange);
    return () => window.removeEventListener('storage', onChange);
  }, []);

  return utmData;
}

export default useUTMData;
