import useSWRImmutable from 'swr/immutable';

import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { UtmData } from '@otovo/shared/lib/analytics/queryParams/UtmLocalStorage';
import { Cloud$ValidatedCampaign } from '@otovo/shared/types/cloudApi';

type DjangoErrorResponse = {
  [key: string]: string[];
};

async function cloudFetcher<T>([url, options]: [
  string,
  RequestInit,
]): Promise<T> {
  let response: Response;

  const defaultOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    ...options,
  };

  try {
    response = await fetch(url, defaultOptions);

    if (response.ok) {
      return response.json();
    }

    // If status is 400, it's likely a form validation error from Django
    if (response.status === 400) {
      const errorData: DjangoErrorResponse = await response.json();
      throw new Error(JSON.stringify(errorData));
    }

    // If status is 500, it's likely an internal server error
    if (response.status === 500) {
      const errorText = await response.text();
      throw new Error(`Internal Server Error: ${errorText}`);
    }

    // For other cases
    throw new Error(`Unhandled response code: ${response.status}`);
  } catch (error) {
    // Handle fetch errors
    throw new Error(`Fetch Error: ${error.message}`);
  }
}

type EmptyObject = Record<string, never>;
type ReferralCode = {
  referral_code: string;
};

function isReferralCode(obj: any): obj is ReferralCode {
  return obj && typeof obj.referral_code === 'string';
}

function isUtmData(obj: any): obj is UtmData {
  return (
    obj &&
    obj.utm_data &&
    typeof obj.utm_data.utm_source === 'string' &&
    typeof obj.utm_data.utm_campaign === 'string'
  );
}

export default function useCampaignValidator(
  campaign: ReferralCode | { utm_data: UtmData } | EmptyObject,
) {
  const { BU_CONFIG } = useGlobalConfig();

  const shouldFetch =
    BU_CONFIG.market && (isReferralCode(campaign) || isUtmData(campaign));

  const fetchURL = `${process.env.NEXT_PUBLIC_OTOVOAPI_URL}/web/campaigns/validate/`;
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      ...campaign,
      country: BU_CONFIG.market,
    }),
  };

  const { data, error, isLoading } = useSWRImmutable<Cloud$ValidatedCampaign>(
    shouldFetch ? [fetchURL, options] : null,
    cloudFetcher,
    {
      shouldRetryOnError: false,
    },
  );

  return {
    isValidating: isLoading,
    validateFetchError: error,
    validatedCampaign: data || null,
  };
}
