import { useTheme } from '@emotion/react';
import { Box } from '@otovo/rainbow';
import { Rainbow$Props } from '../../../types/rainbow';

type Props = {
  size?: string;
  bg?: string;
  color?: string;
  iconWidth?: string;
} & Rainbow$Props;

const Checkmark = ({ size = '5', bg, color, ...rest }: Props) => {
  const theme = useTheme();
  return (
    <Box
      as="span"
      width={size}
      height={size}
      fontSize={theme.sizes?.[size] || size}
      color={color || theme.alias.border_100}
      {...rest}
    >
      <svg
        height="1em"
        width="1em"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <circle
            fill={bg || theme.alias.background_300}
            cx="12"
            cy="12"
            r="12"
          />
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="m10.1428571 13.1286797 5.4393399-5.43933987c.5857864-.58578644 1.5355339-.58578644 2.1213203 0 .5857865.58578644.5857865 1.5355339 0 2.12132034l-6.5 6.50000003c-.5857864.5857864-1.53553389.5857864-2.12132033 0l-2.78571428-2.7857143c-.58578644-.5857865-.58578644-1.5355339 0-2.1213204.58578643-.5857864 1.5355339-.5857864 2.12132034 0z"
          />
        </g>
      </svg>
    </Box>
  );
};

export default Checkmark;
