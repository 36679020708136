import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'WelcomeBack';

export default defineMessages({
  title: {
    id: `${I18N_NAMESPACE}.title`,
    defaultMessage: 'Welcome back!',
  },

  description: {
    id: `${I18N_NAMESPACE}.description`,
    defaultMessage: 'Your previous roof calculation was for {address}.',
  },

  goToProductsPage: {
    id: `${I18N_NAMESPACE}.goToProductsPage`,
    defaultMessage: 'Go to calculation',
  },

  goToProductsPageDetailed: {
    id: `${I18N_NAMESPACE}.goToProductsPageDetailed`,
    defaultMessage: 'Go to your previous calculation',
  },
});
