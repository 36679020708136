import styled from '@emotion/styled';
import RainbowLink from '../RainbowLink/RainbowLink';
import { whiteToDefaultLink } from './animations';

type Props = {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
};

const ArrowRight = (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    className="inline pl-1"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="5" y1="12" x2="19" y2="12" />
    <polyline points="12 5 19 12 12 19" />
  </svg>
);

const AnimatedLink = styled.span(() => ({
  animation: whiteToDefaultLink,
}));

const BannerCTA = ({ children, onClick, href }: Props) => {
  return (
    <AnimatedLink>
      <RainbowLink
        className="whitespace-nowrap text-unset"
        href={href}
        onClick={onClick}
      >
        {children}
        {ArrowRight}
      </RainbowLink>
    </AnimatedLink>
  );
};

export default BannerCTA;
